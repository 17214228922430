import React from "react";
import IconedButton from "../../components/button/IconedButton";
import "./pricingListItem.css";
import useSubscription from "views/subscription/hooks/useSubscription";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "hooks/useAuth";
import useOrder from "hooks/useOrder";
import { setSelectedPlan } from "redux-store/slices/globals";
import { toast } from "react-toastify";

/**
 * Component for individual pricing list item.
 *
 * @param {item} Object
 * @param {yearly} Boolean
 * @param {showForm} Function
 * @param {setPlan} Function
 * @param {showPayment} Function
 * @returns
 */
const PricingListItem = ({ item }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { getMyActivePlan } = useSubscription();
  const { isUserInIndia } = useSelector((state) => state.globals);

  const { displayText, period, userType, billing_amount, billing_amount_USD } =
    getMyActivePlan() || {};

  const billAmt = isUserInIndia ? billing_amount : billing_amount_USD;

  const isCurrentPlan = displayText
    ? item.packageName === displayText &&
      userType === item?.userType &&
      (item?.period === period || item?.type === period)
    : false;

  const { createOrder, createPaymentLink } = useOrder();

  const actionHandler = () => {
    if (userType && item?.userType !== userType)
      return toast.error(
        "You can't purchase this plan as your user type is different."
      );

    // loose check is intentional, don't change it
    if (userType && billAmt != 0)
      if (item?.price > billAmt) {
        // do nothing
      } else
        return dispatch(setSelectedPlan({ alreadySubscribed: true, ...item }));

    dispatch(
      setSelectedPlan({
        ...item,
        price: billAmt > 0 ? item?.price - billAmt : item?.price,
      })
    );

    if (isAuthenticated) {
      if (process.env.REACT_APP_STANDARD_CHECKOUT === "true") createOrder(item);
      else createPaymentLink(item);
    }
  };

  const oldPrice = isUserInIndia
    ? Math.round((item?.price * 1.44) / 50) * 50
    : Math.round(item?.price * 1.44);

  return (
    <div
      className={`pricing-list-item position-relative ${
        isCurrentPlan ? "current-active-plan" : ""
      }`}
    >
      {item.packageName === "Essential" && (
        <p className="recommend-label">BESTSELLER</p>
      )}
      <h2 className="item-price position-relative">
        {item?.type === "year" && (
          <span className="pricing-striked">
            {isUserInIndia ? "₹" : "$"}
            &nbsp;{oldPrice}
          </span>
        )}

        <span>
          {isUserInIndia ? "₹" : "$"} {Math.round(item.price * 100) / 100}
        </span>
        <span className="item-type">
          /per {item?.type} {isUserInIndia ? "+ GST" : "+ tax"}
        </span>
      </h2>
      {/* {item.oldprice && <p className="line-through">{item.oldprice}</p>} */}
      <div className="item-package-name-wrapper">
        <p className="item-package-name">{item.packageName}</p>
        <img
          src={item.packageIcon}
          alt={item.packageIcon}
          className="item-package-icon"
        />
      </div>
      <p className="item-package-desciption">{item.packageDescription}</p>
      <div className="seperator"></div>
      <ul className="item-package-features">
        {item.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      {isCurrentPlan ? (
        <div className="current-plan">
          <span>Current Plan</span>
          <span>✔</span>
        </div>
      ) : (
        <div className="item-package-button">
          <IconedButton
            title="Get Started"
            icon={true}
            onClick={actionHandler}
          />
        </div>
      )}
    </div>
  );
};
export default PricingListItem;
