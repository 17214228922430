import React from "react";
import { toast } from "react-toastify";
import { lsProxy } from "utils/helpers/localstorage";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import ENV from "utils/helpers/env";

const firebaseConfig = {
  apiKey: "AIzaSyD4X2sTTpnwlGgQ8BOOjZ6qrDrY01qgyOE",
  authDomain: "kwikpic-app-2.firebaseapp.com",
  projectId: "kwikpic-app-2",
  storageBucket: "kwikpic-app-2.appspot.com",
  messagingSenderId: "9957195880",
  appId: "1:9957195880:web:54f92f3d035f4c34e57bdd",
  measurementId: "G-5L00R81PKT",
};

const urlParams = new URLSearchParams(window.location.search);
const isWebView = urlParams.has("token") || urlParams.has("frontendType");

export const pushSupported = async () => {
  return (
    !isWebView &&
    (await isSupported()) &&
    navigator.serviceWorker &&
    (window.location.protocol === "https:" ||
      window.location.hostname === "localhost")
  );
};

const register = async (messaging) => {
  let permission = false;

  try {
    await navigator.serviceWorker.register("/firebase-messaging-sw.js");
    const reg = await navigator.serviceWorker.ready;

    const notificationPermission = await Notification.requestPermission();

    if (notificationPermission == "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration: reg,
      });

      if (currentToken) {
        lsProxy.setItem("fcmToken", currentToken);
        permission = true;
      } else {
        lsProxy.removeItem("fcmToken");
        permission = false;
      }
    }
  } catch (e) {
    // messaging is not supported or website is not served over HTTPS
  } finally {
    document.removeEventListener("click", register);
  }

  return permission;
};

const useNotifications = () => {
  React.useEffect(() => {
    let interval;

    (async () => {
      const supported = await pushSupported();

      if (!supported) return;

      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      const registerProxy = async () => {
        const permission = await register(messaging);
        if (!permission) clearInterval(interval);

        document.removeEventListener("click", registerProxy);
      };

      document.addEventListener("click", registerProxy);

      if (messaging)
        onMessage(messaging, (payload) => {
          toast(
            <>
              <img
                src={ENV.WL_PRIMARY_LOGO}
                className="mb-2"
                style={{
                  maxHeight: "20px",
                }}
              />
              <h5 className="font-bold font-16 mb-1">
                {payload.notification.title}
              </h5>
              <p className="mb-0 font-14 font-base">
                {payload.notification.body}
              </p>
            </>,
            {
              containerId: "notifications",
            }
          );
        });

      // ping sw every 20 seconds
      navigator.serviceWorker.ready.then((sw) => {
        interval = setInterval(() => {
          sw.active?.postMessage("hello from client");
        }, 20000);
      });
    })();

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useNotifications;
