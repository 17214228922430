import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  CHANGE_FAVORITE_STATUS,
  DELETE_IMAGE_OF_FOLDER,
  IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
  UNDO_DELETE_IMAGES,
} from "redux-store/sagas/saga-actions";
import { getCFURL, getImageName, getNonAuthURL } from "utils/helpers";
import CommentBoxModal from "views/components/modals/CommentBoxModal";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import LinkShareModal from "views/components/modals/LinkShareModal";
import { handleDownload, allowShareDownload } from "views/download/helpers";
import { LOG_TYPE } from "redux-store/sagas/images";
import {
  useLightboxState,
  useController,
} from "yet-another-react-lightbox/core";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import ProductSalesIcons from "./ProductSalesIcons";
import webShare from "utils/helpers/web-share";
import dayjs from "dayjs";
import { DOWNOQ_OFFSET_MAX } from "./FolderImages";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import css from "./Lightbox.module.css";
const SelectQualityModal = React.lazy(() => import("./SelectQualityModal"));

const MODAL_TYPES = {
  COMMENT: "comment",
  SHARE: "share",
  CONFIRMATION: "confirmation",
  NONE: {
    type: null,
    data: null,
  },
};

const DeleteIcon = ({
  setViewConfirmationModal,
  viewConfirmationModal,
  images,
  totalCount,
  closeImageView,
}) => {
  const { currentIndex, slides } = useLightboxState();
  const { prev } = useController();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const _id = params.get("groupId");
  const folderId = params.get("folderId");

  const handleDeleteImage = () => {
    // using closeImageView to as in FolderImages
    // GalleryModal is rendered conditionally based on fullscreenImages length
    // Deleting last image will cause modal close before the close() callback is called
    // causing imageId to be still present in the page URL
    if (totalCount === 1) closeImageView();

    const authCode = images?.[currentIndex]?.authCode;
    const url = images?.[currentIndex]?.url;
    const nonAuthUrl = getNonAuthURL(url, authCode);

    if (currentIndex === slides.length - 1) prev();

    if (folderId === "deleted") {
      dispatch({
        type: UNDO_DELETE_IMAGES,
        payload: {
          src: "full-screen-restore",
          groupId: _id,
          urls: [nonAuthUrl],
          nonAuthUrl: url,
        },
      });
    } else {
      dispatch({
        type: DELETE_IMAGE_OF_FOLDER,
        payload: {
          _id,
          url: nonAuthUrl,
          nonAuthUrl: url,
        },
      });
    }
  };

  return (
    <>
      <li className="nk-quick-nav-icon">
        <img
          {...(folderId === "deleted"
            ? {
                src: "/assets/icons/restore-white.png",
                alt: "Restore",
                title: "Restore",
                onClick: handleDeleteImage,
              }
            : {
                src: "/assets/icons/bin.png",
                alt: "Delete",
                title: "Delete",
                onClick: () => setViewConfirmationModal(true),
              })}
        />
      </li>
      <ConfirmationModal
        title="Delete Image"
        show={viewConfirmationModal}
        message="Are you sure you want to delete this Image?"
        onCancel={() => setViewConfirmationModal(false)}
        onConfirm={() => {
          handleDeleteImage();
          setViewConfirmationModal(false);
        }}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  );
};

export const DownloadIcon = ({
  groupId,
  image,
  iconClass = "",
  progressClass = "",
}) => {
  const dispatch = useDispatch();
  const [qualityModal, setQualityModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const { downOQ, downOQUpdateTime } = getLocalStorageUser();

  const {
    leaveParticipantSettings: { isAdmin },
    downloadSettings: { allowDownload, originalDownload },
  } = useSelector((state) => state.settings);

  const downloadWithDispatch = ({ originalQuality }) => {
    const id = image?.imageId ?? image?._id;
    const downOQTimeDiff = dayjs().diff(dayjs(downOQUpdateTime || 0), "minute");

    allowShareDownload(
      isAdmin,
      allowDownload,
      () => {
        if (
          (typeof downOQ !== "boolean" ||
            downOQTimeDiff >= DOWNOQ_OFFSET_MAX) &&
          typeof originalQuality !== "boolean" &&
          originalDownload
        )
          return setQualityModal(true);

        handleDownload(
          image?.url,
          (originalQuality ?? downOQ) && originalDownload,
          () => {
            dispatch({
              type: IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
              payload: {
                groupId,
                imageIds: [id],
                logType: LOG_TYPE.DOWNLOAD,
              },
            });
          },
          (p) => setProgress(p)
        );
      },
      "Download"
    );
  };

  return (
    <>
      {progress > 0 && progress < 100 ? (
        <div
          className={`${css.progress} ${progressClass}`}
          style={{ "--progress": `${progress}%` }}
        >
          <progress value={progress} max="100" className="visually-hidden" />
        </div>
      ) : (
        <>
          <img
            src="/assets/icons/download.png"
            onClick={() => downloadWithDispatch({})}
            alt="Download"
            title="Download"
            className={iconClass}
          />
        </>
      )}
      <React.Suspense fallback={<SuspenseLoader screenCenter />}>
        {qualityModal && (
          <SelectQualityModal
            show={true}
            downloadCallback={downloadWithDispatch}
            onHide={() => setQualityModal(false)}
          />
        )}
      </React.Suspense>
    </>
  );
};

export default function LightboxToolbar({
  closeImageView,
  imgIndex,
  totalCount,
  images,
  fetchData,
  addToCart,
  removeFromCart,
}) {
  const [modal, setModal] = useState(MODAL_TYPES.NONE);

  const {
    groupDetails: { isForProductSale },
    leaveParticipantSettings: { isAdmin },
    downloadSettings: { allowDownload },
  } = useSelector((state) => state.settings);

  const { mappedCartItems } = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const page = Number(params.get("page"));
  const folderId = params.get("folderId");
  const groupId = params.get("groupId");

  const { _id: currentUserId } = getLocalStorageUser();
  const isUploadByUser = images[imgIndex]?.uploadedBy === currentUserId;

  const getImagesData = () => {
    fetchData(page);
  };

  const authCode = images?.[imgIndex]?.authCode;
  const hasAuthCode = authCode ? authCode != "NP" : false;

  const id =
    folderId === "my-photos" ||
    folderId === "purchased" ||
    folderId === "deleted"
      ? images?.[imgIndex]?.imageId
      : images?.[imgIndex]?._id;

  React.useEffect(() => {
    if (!id) return;

    dispatch({
      type: IMAGE_IMPRESSION_DOWNLOAD_ANALYTICS,
      payload: {
        groupId,
        imageIds: [id],
        logType: LOG_TYPE.IMPRESSION,
      },
    });
  }, [id]);

  const toggleFavorite = useCallback(
    (url, index, authCode, isFavorite) => {
      const actualUrl = getNonAuthURL(url, authCode);

      dispatch({
        type: CHANGE_FAVORITE_STATUS,
        payload: {
          _id: groupId,
          url: actualUrl,
          index,
          page: +params.get("page"),
          fromModal: true,
          folderId,
          isFavorite,
        },
      });
    },
    [dispatch, groupId, params, folderId]
  );

  return (
    <>
      <div>
        <img
          className="navigate_icons gallary-modal-back-button"
          onClick={closeImageView}
          src="/assets/icons/arrow.svg"
          alt="close"
        />
      </div>
      <div className="image_count">{`${imgIndex + 1}/${totalCount}`}</div>
      <div className="image_name">
        {folderId !== "favorites" && getImageName(images[imgIndex]?.url)}
      </div>
      <div className="overlayAction">
        <ul className="d-flex align-items-center">
          {isForProductSale &&
          folderId !== "purchased" &&
          folderId !== "deleted" &&
          currentUserId !== images[imgIndex]?.uploadedBy &&
          !hasAuthCode ? (
            <li className="nk-quick-nav-icon">
              <img
                {...(mappedCartItems?.[id]
                  ? {
                      src: "/assets/icons/remove-from-cart.png",
                      onClick: () => removeFromCart(mappedCartItems?.[id]),
                      title: "Remove from cart",
                    }
                  : {
                      src: "/assets/icons/add-to-cart.png",
                      onClick: () => addToCart(id),
                      title: "Add to cart",
                    })}
              />
            </li>
          ) : null}

          <li className="nk-quick-nav-icon">
            <DownloadIcon groupId={groupId} image={images[imgIndex]} />
          </li>

          <li className="nk-quick-nav-icon">
            <img
              src="/assets/icons/share.png"
              onClick={() => {
                allowShareDownload(isAdmin, allowDownload, async () => {
                  const url = getCFURL({ url: images?.[imgIndex]?.url });

                  await webShare(url, url, () => {
                    setModal({
                      type: MODAL_TYPES.SHARE,
                      data: url,
                    });
                  });
                });
              }}
              alt="Share"
              title="Share"
            />
          </li>

          {folderId !== "deleted" && (
            <li className="nk-quick-nav-icon">
              <img
                onClick={() => {
                  toggleFavorite(
                    images?.[imgIndex].url,
                    imgIndex,
                    authCode,
                    images?.[imgIndex].isFavorite
                  );
                }}
                alt="Favorite"
                {...(images?.[imgIndex]?.isFavorite
                  ? {
                      src: "/assets/icons/favorite-filled.png",
                      title: "Remove from favorites",
                    }
                  : {
                      src: "/assets/icons/favorite.png",
                      title: "Add to favorites",
                    })}
              />
            </li>
          )}

          {folderId === "favorites" && (
            <li className="nk-quick-nav-icon">
              <img
                src="/assets/icons/comment-box.svg"
                onClick={() => {
                  setModal({ type: MODAL_TYPES.COMMENT });
                }}
                alt="Comment"
                title="Comment"
              />
            </li>
          )}

          {(isAdmin || isUploadByUser) && (
            <DeleteIcon
              key="delete"
              images={images}
              totalCount={totalCount}
              setViewConfirmationModal={(v) =>
                v
                  ? setModal({ type: MODAL_TYPES.CONFIRMATION })
                  : setModal(MODAL_TYPES.NONE)
              }
              viewConfirmationModal={modal.type === MODAL_TYPES.CONFIRMATION}
              closeImageView={closeImageView}
            />
          )}
        </ul>
      </div>
      {modal.type === MODAL_TYPES.SHARE && (
        <LinkShareModal
          show={true}
          onHide={() => setModal(MODAL_TYPES.NONE)}
          title=""
          url={modal.data}
        />
      )}
      {modal.type === MODAL_TYPES.COMMENT && (
        <CommentBoxModal
          show={true}
          getImagesData={getImagesData}
          image={images?.[imgIndex]}
          onHide={() => setModal(MODAL_TYPES.NONE)}
        />
      )}
      {isForProductSale && (
        <ProductSalesIcons
          authCode={hasAuthCode}
          uploadedByCurrentUser={isUploadByUser}
          src="fullsize"
        />
      )}
    </>
  );
}
